<template>
  <div class="promoter-list" ref="container">
    <div class="header">
      <div class="promoterHeader bg-color-red">
        <div class="headerCon acea-row row-between-wrapper">
          <div>
            <div class="name">推广人数</div>
            <div>
              <span class="num">{{ peopleNum }}</span>人
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list">
      <list  v-model="loading" :finished="finished" finished-text="没有更多了" @load="getList">
        <div class="item acea-row row-between-wrapper" v-for="(val, index) in peopleData" :key="index">
          <div class="picTxt acea-row row-between-wrapper">
            <div class="pictrue">
              <img :src="val.headimgurl ? val.headimgurl:auth" />
            </div>
            <div class="text">
              <div class="name line1">手机号: {{val.mobile}}</div>
              <div>加入时间: {{val.create_time}}</div>
            </div>
          </div>
        </div>
      </list>
    </div>
  </div>
</template>
<script>
import User from "@api/user";
import { list } from "vant";
export default {
  name: "PromoterList",
  components: {
    list
  },
  props: {},
  data: function() {
    return {
      fixedState: false,
      childCount: 2,
      numberCount: 2,
      orderCount: 2,
      peopleNum: "1",
      peopleData: [],
      loading: false,
      finished: false,
      auth: require('../../../../../../public/static/images/vip/touxiang_1.png')
    };
  },
  methods: {
    async getList() {
      try {
        const res = await User.getMySpread();
        this.peopleNum = res.data.total;
        if (res.data.total > 0) {
          for (let i in res.data.data){
            this.peopleData.push(res.data.data[i]);
          }
          if (res.data.total == this.peopleData.length) this.finished = true, this.loading = false;
        } else {
          this.finished = true, this.loading = false;
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
};
</script>


<style lang="scss" scoped>
@import "../index.scss";
/*推广人列表*/
.promoterHeader {
  width: 100%;
  height: 110.00000000000001px;
}

.promoterHeader .headerCon {
  width: 100%;
  height: 100%;
  padding: 0 44px 0 27.500000000000004px;
  font-size: 14.000000000000002px;
  color: #fff;
  // background-image: url("../images/transparent.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.promoterHeader .headerCon .name {
  margin-bottom: 1px;
}

.promoterHeader .headerCon .num {
  font-size: 25px;
}

.promoterHeader .headerCon .iconfont {
  font-size: 62.5px;
}
.promoter-list .header {
  padding-bottom: 6px;
}

.promoter-list .nav {
  background-color: #fff;
  height: 43px;
  line-height: 43px;
  font-size: 14.2px;
  color: #282828;
  border-bottom: 1px solid #eee;
}

.promoter-list .nav .item {
  height: 100%;
}

.promoter-list .nav .item.on {
  color: #e93323;
  border-bottom: 2.5px solid #e93323;
}

.promoter-list .search {
  width: 100%;
  background-color: #fff;
  height: 43px;
  padding: 0 15px;
}

.promoter-list .search .input {
  width: 315px;
  height: 30px;
  border-radius: 25px;
  background-color: #f5f5f5;
  text-align: center;
  position: relative;
}

.promoter-list .search .input input {
  height: 100%;
  font-size: 13px;
  width: 310px;
  text-align: center;
}

.promoter-list .search .input input::placeholder {
  color: #bbb;
}

.promoter-list .search .input .iconfont {
  position: absolute;
  right: 14.2px;
  color: #999;
  font-size: 14.2px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.promoter-list .search .iconfont {
  font-size: 20px;
  color: #515151;
}

.promoter-list .list .sortNav {
  background-color: #fff;
  height: 38px;
  border-bottom: 1px solid #eee;
  color: #333;
  font-size: 14.2px;
}

.promoter-list .list .sortNav.on {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}

.promoter-list .list .sortNav .sortItem {
  text-align: center;
  flex: 1;
  -webkit-flex: 1;
  -o-flex: 1;
  -ms-flex: 1;
}

.promoter-list .list .sortNav .sortItem img {
  width: 12px;
  height: 12px;
  margin-left: 3px;
  vertical-align: -1.5px;
}

.promoter-list .list .sortList {
  margin-top: 38px;
}

.promoter-list .list .item {
  background-color: #fff;
  border-bottom: 1px solid #eee;
  height: 76px;
  padding: 0 15px 0 10px;
  font-size: 12px;
  color: #666;
}

.promoter-list .list .item .picTxt {
  width: 320px;
}

.promoter-list .list .item .picTxt .pictrue {
  width: 53px;
  height: 53px;
  overflow: hidden;
  border-radius: 50%;
}

.promoter-list .list .item .picTxt .pictrue img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1.5px solid #fff;
  box-shadow: 0 0 3.54px #aaa;
  -webkit-box-shadow: 0 0 3.54px #aaa;
  -moz-box-shadow: 0 0 3.54px #aaa;
  -o-box-shadow: 0 0 3.54px #aaa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.promoter-list .list .item .picTxt .text {
  width: 257px;
  font-size: 12px;
  color: #666;
}

.promoter-list .list .item .picTxt .text .name {
  font-size: 14.2px;
  color: #333;
  margin-bottom: 2px;
}

.promoter-list .list .item .right {
  width: 120px;
  text-align: right;
  font-size: 11px;
  color: #333;
}
</style>
